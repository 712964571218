import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/login/index.vue';
import Home from '@/views/home/index.vue';
import Call from '@/views/call/index.vue';
import Record from '@/views/record/index.vue';
import Customer from '@/views/customer/index.vue';
import My from '@/views/my/index.vue';

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/login', component: Login },
  { path: '/home', component: Home },
  { path: '/call', component: Call },
  { path: '/record', component: Record },
  { path: '/customer', component: Customer },
  { path: '/my', component: My }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
