<template>
  <div class="page-container">
    <MainLayout title="通话记录">
      <!-- <template #left>
        <van-icon name="arrow-left" @click="goBack">返回</van-icon>
      </template> -->
      <div class="tabs-container">
        <div class="scroll-content">
          <div class="customer-list">
            <!-- 下拉刷新 -->
            <van-pull-refresh
              v-model="refreshing"
              class="content"
              loading-text="刷新中···"
              success-text="刷新成功"
              @refresh="onRefresh"
            >
              <van-list
                :loading="loading"
                :finished="finished"
                :finished-text="finishedText"
                :error="error"
                error-text="请求失败，点击重新加载"
                @load="onLoad"
                @update:error="error = false"
              >
                <!-- 自定义列表每项展示内容 -->
                <div v-for="item in list" :key="item.id" class="content__item">
                  <div class="content__details">
                    <div class="font-color">
                      <div>主叫人：{{ item?.userName }}</div>
                      <div>主叫：{{ item?.fromPhone }}</div>
                      <!-- <div>被叫：{{ item?.toPhone }}</div> -->
                      <div>被叫：{{ formatPhoneNumber(item?.toPhone) }}</div>
                      <div>通话时长(秒)：{{ item?.second }}</div>
                    </div>
                    <div class="content__icons">
                      <van-button size="small" type="primary" block @click.stop="handleAudio(item)">录音</van-button>
                    </div>
                  </div>
                </div>
              </van-list>
            </van-pull-refresh>
          </div>
        </div>
      </div>
      <van-dialog :show="show" title="录音" confirm-button-text="关闭" @confirm="handelCancel">
        <div class="dialog-content">
          <audio ref="audioPlayer" controls></audio>
        </div>
      </van-dialog>
    </MainLayout>
  </div>
</template>

<script setup>
import MainLayout from '@/components/MainLayout.vue';

import { ref, nextTick } from 'vue';
import { showSuccessToast, showFailToast } from 'vant';
import { getCallRecordList, getAudio } from '@/api/record.js';

const refreshing = ref(false);
const loading = ref(false);
const finished = ref(false);
const finishedText = ref('没有更多了');
const error = ref(false);
const show = ref(false);
const audioPlayer = ref(null);
// const customerId = ref(null);

// 列表数据
const list = ref([]);
const limit = ref(20);
const page = ref(1);
// 总条数
const totalNum = ref(0);

// 清空列表并重置分页数据
const clearCustomerList = () => {
  list.value = [];
  page.value = 1; // 每次刷新时重置页码为 1
  finished.value = false; // 重置 finished 状态
};

// 下拉刷新
const onRefresh = () => {
  clearCustomerList(); // 清空现有列表
  finishedText.value = ''; // 清空加载完成提示
  // 加载第一页数据
  fetchCallRecordList(true).finally(() => {
    refreshing.value = false; // 结束刷新状态
  });
};

// 下拉加载更多
const onLoad = () => {
  if (finished.value || loading.value) return; // 如果已经加载完成或正在加载，阻止重复请求
  fetchCallRecordList();
};

// 加载客户数据
const fetchCallRecordList = async () => {
  loading.value = true;
  try {
    const res = await getCallRecordList({
      limit: limit.value,
      page: page.value,
    });

    const { data, code } = res;
    if (code === 2000) {
      if (data?.data?.length > 0) {
        list.value.push(...data.data); // 将新数据合并到列表
        page.value += 1; // 增加页码
        totalNum.value = data.total; // 更新总条数
      }

      if (list.value.length >= totalNum.value) {
        finished.value = true; // 标记为已加载完成
      } else {
        finished.value = false; // 允许继续加载
      }
    } else {
      error.value = true; // 出现错误时设置错误状态
    }
  } catch (err) {
    error.value = true; // 捕获异常并设置错误状态
  } finally {
    loading.value = false; // 无论成功与否，结束加载状态
  }
};

// 听录音
const handleAudio = async (item) => {
  const res = await getAudio({
    callSid: item.callSid,
  });
  const { data, code, msg } = res || {};
  if (code === 2000) {
    const { recordAudioUrl } = data || {};
    show.value = true;
    nextTick(() => {
      if (audioPlayer.value) {
        audioPlayer.value.src = recordAudioUrl;
      }
    });
  } else {
    return showFailToast(msg || '获取录音失败');
  }
};

// const goBack = () => {
//   // 返回上一页的逻辑
// };

const handelCancel = () => {
  show.value = false;
  nextTick(() => {
    if (audioPlayer.value) {
      audioPlayer.value.src = '';
      audioPlayer.value.pause();
      audioPlayer.value.currentTime = 0;
    }
  });
}

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return ''; // 如果电话号码为空则返回空字符串
  const hiddenPhone = phoneNumber.substring(0, 3) + '****' + phoneNumber.substring(7); // 前三位和后四位保留，中间四位替换为星号(*)
  return hiddenPhone; // 返回格式化后的电话号码字符串
}
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.tabs-container {
  position: relative;
  z-index: 1;
  background-color: #f6f6f6; /* 背景色确保和其他内容区分 */
}

/* 滚动区域 */
.scroll-content {
  height: calc(100vh - 92px); /* 减去顶部的导航栏和 van-tab 的高度 */
  overflow-y: auto;
}

.customer-list {
  padding: 10px;
}

.content__item {
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
}

.content__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.font-color {
  color: #404040;
}

.content__icons {
  display: flex;
  gap: 15px;
  font-size: 30px;
}

.content__icons van-icon {
  font-size: 20px;
  color: #007aff;
  cursor: pointer;
}

::v-deep .van-popup--bottom {
  margin-bottom: 50px; /* 添加 margin-bottom 避免与 van-tabbar 重叠 */
}

.dialog-content {
  padding: 10px;
}
</style>
