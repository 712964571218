<template>
  <div class="customer-list">
    <!-- 下拉刷新 -->
    <van-pull-refresh
      v-model="refreshing"
      class="content"
      loading-text="刷新中···"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <van-list
        :loading="loading"
        :finished="finished"
        :finished-text="finishedText"
        :error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
        @update:error="error = false"
      >
        <!-- 自定义列表每项展示内容 -->
        <div v-for="item in list" :key="item.id" class="content__item">
          <div class="content__details">
            <div class="font-color">
              <div>{{ item?.customer_name }}</div>
              <div>电话： {{ item?.phone }}</div>
            </div>
            <div class="content__icons">
              <van-icon name="phone-circle" color="#1989fa" @click.stop="handleCall(item)" />
              <img :src="imgUrl" style="width:30px;height:30px;" @click.stop="handleMoreOptions(item)" />
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-action-sheet
      :show="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      duration="600ms"
      :close-on-click-overlay="true"
      :safe-area-inset-bottom="true"
      @cancel="show = false"
      @select="onSelect"
      @close="onClose"
    />
  </div>
</template>

<script setup>
import { ref, watch, onBeforeMount } from 'vue';
import { showSuccessToast, showFailToast } from 'vant';
import { getCustomer, customerRefunded, pickUpCustomer } from '@/api/customer.js';
import { onFlutterCallback, checkAutoRecord, initApp, onCallFlutter, uploadRecordFile } from '@/utils/callHelper.js';

const props = defineProps({
  activeTab: {
    type: String, // 接收数据的类型
    default: '', // 默认值
  },
});

onBeforeMount(() => {
  initApp();
});

const refreshing = ref(false);
const loading = ref(false);
const finished = ref(false);
const finishedText = ref('没有更多了');
const error = ref(false);
const imgUrl = ref(require('@/assets/point.png'));
const show = ref(false);
const customerId = ref(null);
const actions = props.activeTab === '公海客户' ? [
  { name: '领取' },
] : [
  { name: '退回' },
];

// 列表数据
const list = ref([]);
const limit = ref(20);
const page = ref(1);
// 总条数
const totalNum = ref(0);

// 清空列表并重置分页数据
const clearCustomerList = () => {
  list.value = [];
  page.value = 1; // 每次刷新时重置页码为 1
  finished.value = false; // 重置 finished 状态
};

// 下拉刷新
const onRefresh = () => {
  clearCustomerList(); // 清空现有列表
  finishedText.value = ''; // 清空加载完成提示
  // 加载第一页数据
  fetchCustomers(true).finally(() => {
    refreshing.value = false; // 结束刷新状态
  });
};

// 下拉加载更多
const onLoad = () => {
  if (finished.value || loading.value) return; // 如果已经加载完成或正在加载，阻止重复请求
  fetchCustomers();
};

// 加载客户数据
const fetchCustomers = async () => {
  let customer_type = '';
  if (props.activeTab === '我的客户') {
    customer_type ='public';
  } else {
    customer_type ='public_sea';
  }
  loading.value = true;
  try {
    const res = await getCustomer({
      customer_type,
      limit: limit.value,
      page: page.value,
    });

    const { data, code } = res;
    if (code === 2000) {
      if (data?.data?.length > 0) {
        list.value.push(...data.data); // 将新数据合并到列表
        page.value += 1; // 增加页码
        totalNum.value = data.total; // 更新总条数
      }

      if (list.value.length >= totalNum.value) {
        finished.value = true; // 标记为已加载完成
      } else {
        finished.value = false; // 允许继续加载
      }
    } else {
      error.value = true; // 出现错误时设置错误状态
    }
  } catch (err) {
    error.value = true; // 捕获异常并设置错误状态
  } finally {
    loading.value = false; // 无论成功与否，结束加载状态
  }
};

// 呼叫
const handleCall = (item) => {
  // 获取当前 URL
  const storagePlatform = localStorage.getItem('platform');
  // 检查 URL 中的平台参数
  if (storagePlatform === 'android') {
    // 安卓，先检查录音权限
    checkAutoRecord(item.phone);
  } else if (storagePlatform === 'ios') {
    // 如果是 iOS 平台，直接呼叫，不检查录音
    onCallFlutter(item.phone);
  }
  console.log('客户号码:', item.phone);
};

// 更多操作
const handleMoreOptions = (item) => {
  show.value = true;
  customerId.value = item.id;
  console.log('更多选项:', item.id);
};

// 选择操作
const onSelect = async ({name}) => {
  show.value = false;
  const params = {
    customerIds: [customerId.value],
  };
  const customerApi = name === '领取'? pickUpCustomer : customerRefunded;
  const res = await customerApi(params);
  const { code, msg } = res || {};
  if (code === 2000) {
    showSuccessToast(msg);
    onRefresh();
  } else {
    showFailToast(msg || '操作失败');
  }
}

const onClose = () => {
  show.value = false;
}

watch(
  () => JSON.parse(JSON.stringify(props.activeTab)),
  () => {
    clearCustomerList();
    fetchCustomers();
  }
);

// 监听 Flutter 的回调
window.addEventListener('message', onFlutterCallback)
</script>

<style scoped>
.customer-list {
  padding: 10px;
}

.content__item {
  padding: 30px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
}

.content__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.font-color {
  color: #404040;
}

.content__icons {
  display: flex;
  gap: 15px;
  font-size: 30px;
}

.content__icons van-icon {
  font-size: 20px;
  color: #007aff;
  cursor: pointer;
}

::v-deep .van-popup--bottom {
  margin-bottom: 50px; /* 添加 margin-bottom 避免与 van-tabbar 重叠 */
}
</style>
