import { createApp } from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';  // 确保引入 router
import './router/router-guard';  // 引入路由守卫

const app = createApp(App);
app.use(router);
app.use(Vant);
app.mount('#app');
