<template>
  <div class="page-container">
    <div class="page-content">
      <div id="login-page" class="login-page">
        <table class="custom-table">
          <tr>
            <td class="td_label"><label for="sip_uri">SIP URI:</label></td>
            <td>
              <input id="sip_uri" class="inputSip" type="text" placeholder="sip:1001@10.0.0.0.com" value="sip:1031@60.205.130.191:7059" />
            </td>
          </tr>
          <tr>
            <td class="td_label"><label for="sip_password">SIP Password:</label></td>
            <td>
              <input id="sip_password" class="inputSip" type="password" placeholder="SIP password" value="999999" />
            </td>
          </tr>
          <tr>
            <td class="td_label"><label for="ws_uri">WSS URI:</label></td>
            <td>
              <input id="ws_uri" class="inputSip" type="text" placeholder="wss://example.com" value="wss://fs.scqcyh.com:7070" />
            </td>
          </tr>
          <tr>
            <td class="td_label"><label for="sip_phone_number">Phone Number:</label></td>
            <td>
              <input id="sip_phone_number" class="inputSip" type="text" placeholder="sip:1002@domain" value="1032" />
            </td>
          </tr>
          <tr>
            <td colspan="2" class="td-button">
              <button @click="registerToSIP">注册</button>
              <button @click="makeCall">拨打</button>
              <button @click="hangupCall">挂断</button>
              <button @click="requestPermission">申请麦克风权限</button>
            </td>
          </tr>
        </table>
      </div>

      <div class="audio-container">
        <audio ref="audio" controls></audio>
      </div>
      <textarea ref="logOutput" readonly rows="27" class="log-output"></textarea>
    </div>
    <MainLayout />
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import * as JsSIP from 'jssip';
import MainLayout from "@/components/MainLayout.vue";
import { getPermission } from '@/utils/callHelper.js';

const audio = ref(null);
const logOutput = ref(null);
let userAgent = null;
let currentSession = null;

function logMessage(message) {
  console.info(message);
  if (logOutput.value) {
    logOutput.value.value += message + '\n';
  }
}

function registerToSIP() {
  const sipUri = document.getElementById("sip_uri").value;
  const sipPassword = document.getElementById("sip_password").value;
  const wsUri = document.getElementById("ws_uri").value;

  const socket = new JsSIP.WebSocketInterface(wsUri);
  const configuration = {
    sockets: [socket],
    uri: sipUri,
    password: sipPassword,
    register: true,
  };

  userAgent = new JsSIP.UA(configuration);
  userAgent.on('registered', () => logMessage("已注册到 SIP 服务器"));
  userAgent.on('registrationFailed', (e) => logMessage("注册失败: " + e.cause));
  userAgent.start();
}

function makeCall() {
  const sipPhoneNumber = document.getElementById("sip_phone_number").value;
  if (!userAgent) {
    logMessage("未注册，请先注册");
    return;
  }

  const options = {
    mediaConstraints: { audio: true, video: false },
    eventHandlers: {
      progress: () => logMessage("呼叫正在进行中..."),
      failed: (e) => {
        logMessage("呼叫失败: " + e.cause)
        console.info("呼叫失败: ", e)
      },
      ended: () => logMessage("呼叫结束"),
      confirmed: () => logMessage("呼叫已接通"),
    },
    sessionTimersExpires: 120,
  };

  currentSession = userAgent.call(sipPhoneNumber, options);

  const storagePlatform = localStorage.getItem('platform');
  // if (storagePlatform === 'android') {
  //   logMessage("当前是 android 平台");
  //   // 处理音频流：播放远程音频流
  //   currentSession.connection.addEventListener('addstream', (event) => {
  //     if (audio.value) {
  //       audio.value.srcObject = event.stream;  // 将音频流绑定到音频元素
  //       audio.value.play().catch(error => {
  //         logMessage("音频自动播放被阻止，请手动播放");
  //       });
  //     } else {
  //       logMessage("音频不可用。");
  //     }
  //   });
  // } else {
  logMessage(`当前是 ${storagePlatform} 平台`);
  // 设置音量和取消静音
  if (audio.value) {
    // audio.value.volume = 1.0; // 将音量设置为最大
    audio.value.muted = false; // 确保未静音
  }
  // 使用 track 事件处理音频流
  currentSession.connection.addEventListener('track', (event) => {
    if (event.track.kind === 'audio') {
      audio.value.srcObject = event.streams[0]; // 绑定音频流到音频元素
      audio.value.play().catch(error => {
        logMessage("音频自动播放被阻止，请手动播放" + error.message);
      });
    }
  });
  // }
}

function hangupCall() {
  if (currentSession) {
    currentSession.terminate();
    logMessage("呼叫已挂断");
  } else {
    logMessage("没有活跃的呼叫可以挂断");
  }
}

function requestPermission() {
  navigator.mediaDevices.getUserMedia({audio: true})
      .then(function (stream) {
        logMessage('麦克风权限已授权');
        // 这里可以操作麦克风流，如录音或音频处理
      })
      .catch(function (err) {
        if (err.name === 'NotAllowedError') {
          logMessage('用户拒绝了麦克风权限: ' + err.message);
        } else if (err.name === 'NotFoundError') {
          logMessage('没有检测到麦克风: ' + err.message);
        } else {
          logMessage('请求麦克风失败: ' + err.message);
        }
        getPermission()
      });
}

onMounted(() => {
  audio.value = document.createElement("audio");
  audio.value.autoplay = true;
  document.body.appendChild(audio.value);
});
</script>

<style scoped>
.page-container {
  margin-top: 50px;
}

.login-page {
  width: 100%;
  background-color: #f2f4f4;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-table {
  width: 100%;
}

.td_label {
  font-size: 12px;
  width: 100px;
  text-align: right;
}

.inputSip {
  width: 95%;
}

.td-button {
  text-align: center;
}

.audio-container {
  margin-top: 20px;
}

.log-output {
  width: 100%;
  margin-top: 10px;
  resize: none;
}
</style>
