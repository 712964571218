<template>
  <div class="home-container">
    <div class="home-content">
      <div>欢迎来到首页！</div>
      <van-button type="primary" block @click="jumpLogin">退出登录</van-button>
      <van-button type="primary" block @click="checkAudio">检查录音</van-button>
    </div>
    <MainLayout title="首页">
      <template #left>
        <van-icon name="arrow-left" @click="goBack" />
      </template>
      <!-- 右侧按钮 -->
      <template #right>
        <van-icon name="search" @click="search" />
      </template>
    </MainLayout>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import MainLayout from '@/components/MainLayout.vue';
import { queryAudio } from '@/api/call.js';
import { pushAudioFile, onFlutterCallback, initApp } from '@/utils/callHelper.js';
import { showFailToast } from 'vant';
const router = useRouter();
const audioList = ref([]);

onBeforeMount(() => {
  initApp();
});

const checkAudio = () => {
  queryAudio().then(res => {
    const { code, data, msg } = res || {};
    if (code === 2000) {
      audioList.value = data;
    } else {
      showFailToast(msg || '服务器开小差了');
    }
  });
}
const jumpLogin = () => {
  localStorage.removeItem('token');
  // 点击登录后直接跳转到 home 页
  router.push('/login');
};
const goBack = () => {
  // 返回上一页的逻辑
};

const search = () => {
  console.log('搜索按钮被点击');
  // 搜索的逻辑
};

watch(audioList, (newList) => {
  if (newList.length > 0) {
    newList.forEach(item => {
      pushAudioFile(item.recordAudioUrl);
    });
  }
});

// 监听 Flutter 的回调
window.addEventListener('message', onFlutterCallback)
</script>

<style scoped>
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
  /* 减去底部 TabBar 的高度 */
}

.home-content {
  font-size: 24px;
  color: #333;
}
</style>
