import {showFailToast, showSuccessToast} from 'vant';
import { pushRecord } from '@/api/call.js';

let currentPhoneNumber = null;  // 用于保存当前正在呼叫的电话号码
let recordUrl = null;  // 用于保存录音地址

// 呼叫
export function onCallFlutter(inputValue) {
  const param = {
    methodName: "callUser",
    param: {
      "phone": inputValue
    }
  }
  const result = JSON.stringify(param);
  if (typeof window.flutter_inappwebview !== 'undefined') {
    window.flutter_inappwebview.callHandler("callUser", result);
  }
}

// 跳转设置录音页
export function jumpRecordSettingPage() {
  const param = {
    methodName: "jumpRecordSettingPage",
    param: {}
  }
  const result = JSON.stringify(param);
  if (typeof window.flutter_inappwebview !== 'undefined') {
    window.flutter_inappwebview.callHandler("jumpRecordSettingPage", result);
  }
}

// 检查录音权限
export function checkAutoRecord(phone) {
  // 将电话号码保存到全局变量中，供后续调用使用
  currentPhoneNumber = phone;

  const param = {
    methodName: 'checkAutoRecord',
    param: {}
  };
  const result = JSON.stringify(param);
  if (typeof window.flutter_inappwebview !== 'undefined') {
    window.flutter_inappwebview.callHandler("checkAutoRecord", result);
  }
}

// 操作回调
export async function onFlutterCallback(event){
  const model = event.data;
  // alert('回调----' + JSON.stringify(model))
  if(model.backName=="callBackState"){
    showSuccessToast("呼叫成功！")
  } else if (model.backName == "checkAutoRecordResult") {
    let isCheckSuccess = model.param.checkSuccess;
    if (typeof isCheckSuccess === 'string') {
      isCheckSuccess = (isCheckSuccess === "true")
    }
    let isRecordingEnabled = model.param.result;
    if (typeof isRecordingEnabled === 'string') {
      isRecordingEnabled = (isRecordingEnabled === "true")
    }
    // 能检查录音权限
    if (isCheckSuccess) {
      // 如果录音权限检查成功，执行原逻辑
      if (isRecordingEnabled) {
        if (currentPhoneNumber) {
          onCallFlutter(currentPhoneNumber);  // 开始呼叫
        } else {
          alert('没有号码，无法呼叫')
        }
      } else {
        jumpRecordSettingPage();
      }
    } else {
      // 不能检查录音权限
      const isSetAudio = localStorage.getItem('isSetAudio');
      if (isSetAudio !== '1') {
        // 第一次检查失败，跳转到设置页并存储标识
        jumpRecordSettingPage();
        localStorage.setItem('isSetAudio', '1');
      } else {
        if (currentPhoneNumber) {
          // 第二次检查失败，直接开始呼叫
          onCallFlutter(currentPhoneNumber);
        } else {
          alert('没有号码，无法呼叫')
        }
      }
    }
    // alert('进入检查录音回调')
    // // const isCheckSuccess = model.param.checkSuccess;
    // // 检查录音权限回调
    // const isRecordingEnabled = model.param.result;
    // if (isRecordingEnabled) {
    //   if (currentPhoneNumber) {
    //     onCallFlutter(currentPhoneNumber);  // 开始呼叫
    //   }
    // } else {
    //   console.log('跳转到设置页')
    //   jumpRecordSettingPage();
    // }
  } else if(model.backName == "permissionCallback") {
    //权限回调
    console.log('=================', model.param.desc);
  } else if(model.backName == "callEndBackData"){
    // 拨号完成后的回调
    // 通话结束后推送话单
    const param = model.param.callResultData
    const res = await pushRecord(param);
    const { code, msg } = res || {};
    if (code === 2000) {
      console.log('========推送话单成功=========');
    } else {
      showFailToast(msg || '推送失败');
    }
    // 上传录音
    recordUrl = model.param.callResultData.recordUrl;
    uploadRecordFile();
  } else if(model.backName == "callRecordList"){
    console.log('=================', model.param.data[0].id);
  }
}

// 初始化
export function initApp() {
  const param = {
    methodName: 'initApp',
    param: {
      "baseUrl": "https://erp.scqcyh.com",
    }
  };
  const result = JSON.stringify(param);
  if (typeof window.flutter_inappwebview !== 'undefined') {
    window.flutter_inappwebview.callHandler("initApp", result);
  }
}

// 上传录音
export function uploadRecordFile() {
  const token = localStorage.getItem('token');
  if (recordUrl && token) {
    const param = {
      methodName: 'uploadRecordFile',
      param: {
        "uploadApi": "/java/call/record/upload-card-record-file",
        "authorization": 'JWT ' + token,
        "fileUrl": recordUrl,
      }
    };
    const result = JSON.stringify(param);
    if (typeof window.flutter_inappwebview !== 'undefined') {
      window.flutter_inappwebview.callHandler("uploadRecordFile", result);
    }
  }
}

// 补充上传录音
export function pushAudioFile(recordUrl) {
  const token = localStorage.getItem('token');
  if (recordUrl && token) {
    const param = {
      methodName: 'uploadRecordFile',
      param: {
        "uploadApi": "/java/call/record/upload-card-record-file",
        "authorization": 'JWT ' + token,
        "fileUrl": recordUrl,
      }
    };
    const result = JSON.stringify(param);
    if (typeof window.flutter_inappwebview !== 'undefined') {
      window.flutter_inappwebview.callHandler("uploadRecordFile", result);
    }
  } else {
    // alert('没有token或者录音文件，无法上传')
  }
}

// 单独获取麦克风权限
export function getPermission() {
  const param = {
    methodName: 'getPermission',
    param: {
      "permissionName": "MICROPHONE"
    }
  };
  const result = JSON.stringify(param);
  if (typeof window.flutter_inappwebview !== 'undefined') {
    window.flutter_inappwebview.callHandler("getPermission", result);
  }
}