<template>
  <div class="login-container">
    <img class="logo" src="@/assets/logo.png" alt="Logo" />
    <van-form class="login-form">
      <div class="login-input-form">
        <van-field v-model="tenantName" left-icon="diamond" placeholder="请输入公司名称" />
        <van-field v-model="username" left-icon="contact" placeholder="请输入用户名" />
        <van-field
          v-model="password"
          left-icon="lock"
          :type="passwordVisible ? 'text' : 'password'"
          placeholder="请输入密码"
        >
          <template #right-icon>
            <van-icon :name="passwordVisible ? 'eye-o' : 'closed-eye'" @click="togglePasswordVisibility" />
          </template>
        </van-field>
      </div>
      <van-button type="primary" block @click="handleLogin">登录</van-button>
    </van-form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { showSuccessToast, showFailToast } from 'vant';
import { erpLogin, scrmLogin } from '@/api/login.js'
import { useRouter } from 'vue-router';
import md5 from 'js-md5';

const tenantName = ref('千创云呼');
const username = ref('');
const password = ref('');
const passwordVisible = ref(false);
const router = useRouter();

const handleLogin = async () => {
  if (!tenantName.value || !username.value || !password.value) {
    showFailToast('请填写完整信息');
    return;
  }
  const loginApi = tenantName.value === '千创云呼' ? erpLogin : scrmLogin;
  const params = {
    tenantName: tenantName.value,
    username: username.value,
    password: md5(password.value)
  };

  // 调用 login 接口
  const res = await loginApi(params);
  const { code, data, msg } = res || {};
  if (code === 2000) {
    localStorage.setItem('token', data?.access); // 存储 token
    router.push('/home'); // 登录成功后跳转到 home 页面
  } else {
    showFailToast(msg || '登录失败');
  }
};
const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;  // 切换布尔值，控制密码的显示/隐藏
}
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* 确保容器占满整个视口 */
  padding: 20px;
  overflow: hidden;
  /* 隐藏溢出内容 */
  box-sizing: border-box;
  /* 确保内边距不影响高度 */
}

.logo {
  width: 100px;
  /* 根据实际需要调整宽度 */
  margin-bottom: 20px;
  /* logo 与输入框之间的间距 */
}

.login-form {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 90%;
  max-width: 400px;
}

.van-field {
  margin-bottom: 15px;
}

.van-button {
  margin-top: 20px;
  font-size: 16px;
  padding: 12px 0;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.van-button:hover {
  background-color: #3b82f6;
  /* 修改为您希望的悬停颜色 */
}

.login-input-form {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 10px;
}
</style>
