import { request } from './service.js'; // 替换为实际路径

// 获取
export function getCustomer (query) {
  return request({
    // url: '/api/customer/public/',
    url: '/java/customer/get-list',
    method: 'get',
    params: { ...query }
  })
}

// 退回客户
export function customerRefunded (data) {
  return request({
    url: '/java/customer/refunded',
    method: 'post',
    data,
  })
}

// 领取客户
export function pickUpCustomer (data) {
  return request({
    url: '/java/customer/pick_up',
    method: 'post',
    data
  });
}
