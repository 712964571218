<template>
  <router-view/>
</template>

<script setup>
import {ref, watch, onMounted, onBeforeMount, onBeforeUnmount} from 'vue';
import {queryAudio} from '@/api/call.js';
import {pushAudioFile, onFlutterCallback, initApp} from '@/utils/callHelper.js';
import {showFailToast} from 'vant';

const audioList = ref([]);
let timer = null;

onBeforeMount(() => {
  initApp();
});

const startPolling = () => {
  timer = setInterval(() => {
    checkAudio();
  }, 1000 * 60 * 30); // 5 seconds
}

const stopPolling = () => {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
}

const checkAudio = () => {
  const token = localStorage.getItem('token');
  if (token) {
    queryAudio().then(res => {
      const {code, data, msg} = res || {};
      if (code === 2000) {
        audioList.value = data;
      } else {
        showFailToast(msg || '服务器开小差了');
      }
    });
  }
}

onMounted(() => {
  checkAudio();
  startPolling(); // Start polling when component is mounted
});

onBeforeUnmount(() => {
  stopPolling();
});

watch(audioList, (newList) => {
  if (newList.length > 0) {
    newList.forEach(item => {
      pushAudioFile(item.recordAudioUrl);
    });
  }
});

// 监听 Flutter 的回调
window.addEventListener('message', onFlutterCallback)
</script>

<style>
/* 可以在这里添加全局样式 */
</style>
