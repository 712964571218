<template>
  <div class="page-container">
    <MainLayout title="客户">
      <!-- <template #left>
        <van-icon name="arrow-left" @click="goBack">返回</van-icon>
      </template> -->
      <!-- 右侧按钮 -->
      <!-- <template #right>
        <van-icon name="search" @click="search">搜索</van-icon>
      </template> -->
      <div class="tabs-container">
        <!-- 固定的 van-tabs -->
        <van-tabs v-model="activeTab" sticky @click-tab="onTabChange">
          <van-tab title="我的客户">
            <!-- 滚动的内容区域 -->
            <div class="scroll-content">
              <CustomerList :key="activeTab" :active-tab="activeTab" />
            </div>
          </van-tab>
          <van-tab title="公海客户">
            <!-- 滚动的内容区域 -->
            <div class="scroll-content">
              <CustomerList :key="activeTab" :active-tab="activeTab" />
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </MainLayout>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import MainLayout from '@/components/MainLayout.vue';
import CustomerList from './components/CustomerList.vue';

const activeTab = ref('我的客户'); // 设置为实际标题

const onTabChange = ({ title }) => {
  activeTab.value = title;
};

// const goBack = () => {
//   // 返回上一页的逻辑
// };

const search = () => {
  console.log('搜索按钮被点击');
  // 搜索的逻辑
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.tabs-container {
  position: relative;
  z-index: 1;
  background-color: white; /* 背景色确保和其他内容区分 */
}

.van-tabs {
  position: fixed;
  top: 50px; /* 调整为实际顶部距离，如需要 */
  left: 0;
  right: 0;
  background-color: #f6f6f6; /* 确保固定部分有背景色 */
  z-index: 999;
}

/* 滚动区域 */
.scroll-content {
  height: calc(100vh - 150px); /* 减去顶部的导航栏和 van-tab 的高度 */
  overflow-y: auto;
}
</style>
