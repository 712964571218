<template>
  <div class="call-container">
    <div class="call-content">
      <h3>请输入手机号码</h3>
      <!-- 输入后的号码显示 -->
      <div class="input-display">
        <span>{{ inputNumber }}</span>
      </div>
      <!-- 拨号盘 -->
      <div class="dial-pad">
        <div v-for="row in dialRows" :key="row" class="dial-row">
          <button v-for="num in row" :key="num" class="dial-button" @click="handlePress(num)">
            <div class="number">{{ num }}</div>
            <div class="letters">{{ getLetters(num) }}</div>
          </button>
        </div>
      </div>
      <div class="action-buttons">
        <van-button plain type="primary" @click="call">呼叫</van-button>
        <van-button plain type="danger" @click="deleteLast">删除</van-button>
      </div>
    </div>
    <MainLayout />
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { showToast, showFailToast } from 'vant';
import MainLayout from '@/components/MainLayout.vue';
import { onCallFlutter, checkAutoRecord, onFlutterCallback, initApp } from '@/utils/callHelper.js';

const inputNumber = ref('');
const dialRows = [
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9'],
  ['*', '0', '#']
];

onBeforeMount(() => {
  initApp();
});

// 数字按下
const handlePress = (num) => {
  inputNumber.value += num;
};
// 呼叫
const call = () => {
  if (inputNumber.value) {
    const storagePlatform = localStorage.getItem('platform');
    // 检查 URL 中的平台参数
    if (storagePlatform === 'android') {
      // 安卓，先检查录音权限
      checkAutoRecord(inputNumber.value);
    } else if (storagePlatform === 'ios') {
      // 如果是 iOS 平台，直接呼叫，不检查录音
      onCallFlutter(inputNumber.value);
    }
    inputNumber.value = ''
  } else {
    showFailToast('请输入电话号码');
  }
};
// 删除
const deleteLast = () => {
  inputNumber.value = inputNumber.value.slice(0, -1);
};
// 返回显示在数字下面的字母
const getLetters = (num) => {
  const lettersMap = {
    '2': 'ABC',
    '3': 'DEF',
    '4': 'GHI',
    '5': 'JKL',
    '6': 'MNO',
    '7': 'PQRS',
    '8': 'TUV',
    '9': 'WXYZ'
  };
  return lettersMap[num] || '';
};
// 监听 Flutter 的回调
window.addEventListener('message', onFlutterCallback)
</script>

<style scoped>
.call-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
}

.call-content {
  text-align: center;
  font-size: 24px;
  color: #333;
}

.dial-pad {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dial-row {
  display: flex;
  justify-content: center;
}

.dial-button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 10px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dial-button .number {
  font-weight: bold;
}

.dial-button .letters {
  font-size: 12px;
  color: #666;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.input-display {
  margin: 20px 0;
  font-size: 30px;
  font-weight: bold;
  color: #333;
  min-height: 40px;
  /* 保证无论是否输入内容，间距保持一致 */
}
</style>
