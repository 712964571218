import { request } from './service.js'; // 替换为实际路径

// 登录
export function erpLogin (data) {
  return request({
    url: '/java/user/login',
    method: 'post',
    data
  })
}

export function scrmLogin (data) {
  return request({
    url: 'api/login/',
    method: 'post',
    data
  })
}

// 刷新token
const refreshTken = function () {
  const refresh = util.cookies.get('refresh')
  return request({
    url: 'token/refresh/',
    method: 'post',
    data: {
      refresh: refresh
    }
  })
}
