import { request } from './service.js'; // 替换为实际路径

// 推送话单记录
export function pushRecord (data) {
  return request({
    url: '/java/call/record/upload-card-record',
    method: 'post',
    data,
  })
}

// 检查录音列表
export function queryAudio () {
  return request({
    url: '/java/call/record/get-un-upload-audio',
    method: 'get',
  })
}
