import axios from 'axios';
import { useRouter } from 'vue-router';
import { showSuccessToast, showFailToast } from 'vant';
import util from '@/libs/util';  // 假设 util 里有 baseURL
import router from '@/router'; // 根据你的项目路径修改
// const router = useRouter();

const getErrorMessage = (msg) => {
  if (typeof msg === 'string') {
    return msg
  }
  if (typeof msg === 'object') {
    if (msg.code === 'token_not_valid') {
      localStorage.removeItem('token'); // 清除 token
      router.push({ path: '/login' })
      router.go(0)
      return '登录超时，请重新登录！'
    }
    if (msg.code === 'user_not_found') {
      util.cookies.remove('token')
      util.cookies.remove('uuid')
      router.push({ path: '/login' })
      router.go(0)
      return '用户无效，请重新登录！'
    }
    return Object.values(msg)
  }
  if (Object.prototype.toString.call(msg).slice(8, -1) === 'Array') {
    return msg
  }
  return msg
}
// 创建请求实例
const service = axios.create({
  baseURL: util.baseURL(),  // 使用你定义的 baseURL 方法
  timeout: 20000            // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token'); // 从 localStorage 获取 token
    if (token) {
      config.headers['Authorization'] = 'JWT ' + token; // 添加 token 到请求头中
    }
    return config;
  },
  error => {
    console.error('请求错误:', error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    let dataAxios = response.data;

    if (response.headers['content-disposition']) {
      dataAxios = response; // 处理文件下载情况
    }

    const { code } = dataAxios;
    if (code === undefined) {
      return dataAxios; // 如果没有 code，直接返回数据
    } else {
      switch (code) {
        case 2000:
          return dataAxios;
        case 401:
          showFailToast(`${getErrorMessage(dataAxios.msg)}`)
          localStorage.removeItem('token'); // 清除 token
          console.log("router", router);
          router.push({ path: '/login' }) // 跳转到登录页
          break;
        case 404:
          console.log('资源未找到:', dataAxios.msg);
          break;
        case 400:
          // showFailToast(dataAxios.msg);
          return dataAxios;
        case 4000:
            // 删除cookie
            showFailToast(`${getErrorMessage(dataAxios.msg)}`)
            break
        default:
          showFailToast(`${dataAxios.msg}: ${response.config.url}`);
          break;
      }
    }
  },
  error => {
    console.error('请求失败:', error);
    return Promise.reject(error);
  }
);

export const request = service; // 导出请求实例
