import { request } from './service.js'; // 替换为实际路径

// 获取通话记录列表
export function getCallRecordList (query) {
  return request({
    url: '/java/call/record/page',
    method: 'get',
    params: { ...query }
  })
}

// 获取录音
export function getAudio (query) {
  return request({
    url: '/java/call/record/get-audio',
    method: 'get',
    params: { ...query }
  })
}