import router from './index';  // 确保导入 router 实例

const whiteList = ['/login'];  // 白名单

let platform = "ios";  // 存储平台信息
// 全局路由守卫
router.beforeEach((to, from, next) => {
  const currentUrl = window.location.href;
  // alert('呼叫前查看地址'+currentUrl);
  const token = localStorage.getItem('token');  // 从 localStorage 获取 token
  if (currentUrl.includes("platform=android")) {
    platform = 'android';
  } else if (currentUrl.includes("platform=ios")) {
    platform = 'ios';
  }
  const storagePlatform = localStorage.getItem('platform');
  console.log('=================', storagePlatform);
  if (!storagePlatform) {
    console.log('========进入=========');
    localStorage.setItem('platform', platform);
  }

  if (token) {
    next();  // 如果有 token，直接放行
  } else {
    if (whiteList.includes(to.path)) {
      next();  // 如果是白名单路径，放行
    } else {
      next('/login');  // 否则跳转到登录页
    }
  }
});
