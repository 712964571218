<template>
  <div>
    <van-nav-bar>
      <template #left>
        <slot name="left"></slot>
      </template>
      <template #title>
        {{ title }}
      </template>
      <template #right>
        <slot name="right"></slot>
      </template>
    </van-nav-bar>

    <!-- 中间的内容通过插槽传递 -->
    <div class="container">
      <slot></slot>
    </div>

    <van-tabbar v-model="active" route>
      <van-tabbar-item to="/home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item to="/record" icon="clock-o">记录</van-tabbar-item>
      <van-tabbar-item to="/call" icon="phone-o">呼叫</van-tabbar-item>
      <van-tabbar-item to="/customer" icon="orders-o">客户</van-tabbar-item>
      <van-tabbar-item to="/my" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: '页面标题'
  }
});

const active = ref(0);
</script>

<style scoped>
/* 顶部导航栏样式 */
.van-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  /* background-color: #1989fa !important; */
  /* 加上 !important 提高优先级 */
  /* box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1); */
  /* 阴影效果 */
  --van-nav-bar-icon-color: #000000; /* 修改图标颜色 */
}

/* 中间内容区域样式 */
.container {
  padding-top: 46px; /* 确保内容不被顶部的导航栏遮挡 */
  padding-bottom: 50px; /* 确保内容不被底部的 TabBar 遮挡 */
  overflow: auto; /* 允许内容溢出时滚动 */
  height: calc(100vh - 52px); /* 减去导航栏和 TabBar 的高度 */
  box-sizing: border-box; /* 确保 padding 不影响高度 */
}

/* 底部 TabBar 样式 */
.van-tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
